import React from "react";
// import { Carousel } from "react-responsive-carousel";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import PhoneNumber from "../components/PhoneNumber";
import CardTile from "../components/CardTile";
import ShopArtikelWidget from "../components/ShopCoinWidget";
import { useTranslation } from "react-i18next";
import TitleBar from "../components/TitleBar";
import CarouselBar from "../components/CarouselBar";

library.add(fab, fas);

export default function HomeScreen(props) {
  const { t } = useTranslation(["home_screen"]);
  const carousel_items = [
    {image: '/images/muenzkatalog.jpg', link: '//shop.muenzgalerie.de/kataloge/662152fb80d51baadade01f5', title: "Intermünz-Kurier 193", text: "Unser neuer Katalog für 2024"},
    // {image: '/images/med-goetz-teil-1-crop.jpg', link: '//shop.muenzgalerie.de/kataloge/64f6f01f3e8f9fae4cc7b201', title: t("carousel4_h"), text: t("carousel4_t")},
    {image: '/images/coins-crop.jpg', link: 'world-of-numismatics', title: t("carousel1_h"), text: t("carousel1_t")},
    {image: '/images/schmuck_black-crop.jpg', link: '//mgm-schmuck.de/', title: t("carousel2_h"), text: t("carousel2_t")},
    {image: '/images/head-gold-1-crop.jpg', link: 'gold', title: t("carousel3_h"), text: t("carousel3_t")},
  ]

  return (
    <div className="px-5p">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
        <div className="flex flex-col w-full">
          <CarouselBar items={carousel_items} />


          <div className="grid grid-cols-2 gap-4 p-3 md:flex md:divide-x md:flex-row  md:w-full md:justify-evenly pt-10">
            <div className="md:w-1/4 flex flex-col space-y-2 justify-start items-center">
              <FontAwesomeIcon icon="store" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">
                Click &amp; Collect
              </span>
              <span className="text-gray-500  text-xs md:text-sm text-center">
                {t("order_online")}
              </span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="stamp" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">
                {t("authenticity")}
              </span>
              <span className="text-gray-500  text-xs md:text-sm text-center">
                {t("products_auth")}
              </span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="globe-americas" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">
                {t("worldwide_shipping")}
              </span>
              <span className="text-gray-500  text-xs md:text-sm text-center">
                {t("fast_and_secure")}
              </span>
            </div>
            <div className="md:w-1/4 flex flex-col space-y-2 md:pl-4 justify-start items-center">
              <FontAwesomeIcon icon="phone" size="lg" />
              <span className="tracking-widest text-center text-sm md:text-base">
                {t("telephone_support")}
              </span>
              <div className="flex flex-col w-full">
                <div className="text-xs md:text-sm text-center w-full">
                  <PhoneNumber
                    number={"+49895233660"}
                    text={"+49 (89) 5233660"}
                  />
                </div>
                <span className="text-gray-500 text-xs md:text-sm text-center w-full">
                  Mo-Fr: 9:00 - 18:00 {t("uhr")}
                </span>
              </div>
            </div>
          </div>
          {/* <p className="bg-blue-100 border border-dashed border-blue-800 p-10 text-lg text-center text-blue-700">Sehr geehrte Kunden,<br /> bitte beachten Sie, dass wir am 21. Februar geschlossen haben. Ab 22. Februar sind wir wieder für Sie da!</p> */}
          <h2 className="md:px-0 pt-4 tracking-widest font-semibold uppercase text-center md:text-4xl text-gray-700">
            Münzgalerie München
          </h2>
          <h2 className="md:px-0 pb-4 md:pt-3 tracking-widest uppercase text-center md:text-4xl text-gray-700">
            {t("since_1975")}
          </h2>

          <div className="h-full flex items-center flex-col space-y-2 place-content-center py-2 space-y-2">
            <p className="text-justify italic space-y-4 divide-y flex flex-col">
              <span className="text-lg px-10 md:px-48 text-gray-700 pt-4">
                {t("main_text")}
              </span>
            </p>
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-x-32 md:px-20 pt-12">
                <CardTile
                  title={t("topic1_h")}
                  link={"/coins/world-of-numismatics"}
                  image={"/images/coinsThumb.jpg"}
                  description={t("topic1_t")}
                />
                <CardTile
                  title={t("topic2_h")}
                  link={"//mgm-schmuck.de"}
                  image={"/images/schmuckThumb.jpg"}
                  description={t("topic2_t")}
                  external={true}
                />
                <CardTile
                  title={t("topic3_h")}
                  link={"/gold/overview"}
                  image={"/images/edelmetalleThumb.jpg"}
                  description={t("topic3_t")}
                />
              </div>
            </div>
          </div>
          <TitleBar description={t("visit_onlineshop")}/>
          <div className="flex flex-col w-full" style={{ width: "100%" }}>
            <ShopArtikelWidget />
          </div>
        </div>
      </div>
    </div>
  );
}
